import { graphql } from 'gatsby';
import React, { Fragment } from 'react';
import { PrivacyPolicyFragment, PrivacyPolicyQuery } from '../entities/operationResults';
import withDefaultTransition from '../hocs/withDefaultTransition';
import BodyText from './atoms/BodyText';
import { Section } from './atoms/Section';
import Title from './atoms/Title';
import ContentSection from './molecules/ContentSection';
import Hero from './organisms/Hero';
import Meta from './atoms/Meta';
import { removeTags } from '../utils/removeTags';

interface Props {
    data: PrivacyPolicyQuery;
}
export const query = graphql`
    query PrivacyPolicyQuery($site: String, $uri: String) {
        craft {
            entry(site: $site, uri: $uri) {
                ...PrivacyPolicyFragment
            }
        }
    }
`;

export const fragment = graphql`
    fragment PrivacyPolicyFragment on Craft_PrivacyPolicy {
        title
        hero {
            ...HeroFragment
        }
        contentBlocks {
            ...PrivacyIntroFragment
        }
        contentWithTitle {
            ...PrivacyContentFragment
        }
        seo {
            ...MetaFragment
        }
    }

    fragment PrivacyIntroFragment on Craft_ContentBlocksTextBlock {
        text {
            content
        }
    }

    fragment PrivacyContentFragment on Craft_ContentWithTitleContenttext {
        bodyTitle
        body
    }
`;

const PrivacyPolicy: React.FC<Props> = ({ data }) => {
    const entry = data.craft.entry as PrivacyPolicyFragment;

    const hero = entry.hero && entry.hero[0];
    const introBlocks = entry.contentBlocks && entry.contentBlocks[0];
    const intro = introBlocks && introBlocks.text;
    const content = entry.contentWithTitle && entry.contentWithTitle;
    const meta = entry.seo && entry.seo[0];

    return (
        <Fragment>
            <Meta
                title={meta && meta.seoTitle ? meta.seoTitle : entry.title}
                description={
                    meta && meta.seoDescription
                        ? meta.seoDescription
                        : hero && hero.description && removeTags(hero.description.content)
                }
                image={meta && meta.seoImage ? meta.seoImage : null}
            />
            <Section>{hero && <Hero data={hero} />}</Section>
            <Section paddingLeft={2}>
                {intro && (
                    <ContentSection>
                        <BodyText>
                            {intro.content && <div dangerouslySetInnerHTML={{ __html: intro.content }} />}
                        </BodyText>
                    </ContentSection>
                )}
                {content &&
                    content.map((item, i) =>
                        item ? (
                            <ContentSection key={i}>
                                <Title>{item.bodyTitle && item.bodyTitle}</Title>
                                <BodyText>{item.body && item.body}</BodyText>
                            </ContentSection>
                        ) : null
                    )}
            </Section>
        </Fragment>
    );
};

export default withDefaultTransition(PrivacyPolicy);
